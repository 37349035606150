import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { animateScroll as scroll } from "react-scroll"

const Thankyou = ({ setSubmitted }) => {
  return (
    <>
      <div className="page-heading">
        <h1 className="text-uppercase">
          <span className="d-block">Thank You</span>
          For Entering!
        </h1>
        <p className="knockoutLiteweightRegular text-xl text-uppercase">
          <span className="d-block">Come back tomorrow for </span> another
          chance to win!
        </p>
        <a
          href="https://www.snickers.com/"
          className="btn btn-primary text-uppercase rounded-0 w-auto"
          role="button"
          target="_blank"
          rel="noopener noreferrer"
        >
          Take me to snickers.com
          <span className="visually-hidden">opens in a new window</span>
        </a>
      </div>

      <div className="rookie-mistake">
        <figure className="text-center">
          <StaticImage
            src="../images/rookie-mistake.png"
            alt="Rookie Mistake"
          />
        </figure>
        <p className="knockoutLiteweightRegular text-xl text-uppercase">
          <span className="d-block">Share your Rookie Mistake and you</span>
          could win tickets to super bowl LVI.
        </p>
        <button
          className="btn btn-primary text-uppercase rounded-0"
          onClick={() => {
            scroll.scrollToTop()
            setSubmitted(false)
          }}
        >
          Enter
        </button>
      </div>

      <div className="peanut-hldr">
        <figure className="text-center">
          <StaticImage src="../images/peanut.png" alt="Peanut" />
        </figure>
      </div>
    </>
  )
}

export default Thankyou
